/* eslint no-console:0 */
require("@rails/ujs").start();
require("turbolinks").start();

import m from "materialize-css";
global.materialize = m;

// Load Materalize
document.addEventListener("turbolinks:load", function() {
  Waves.displayEffect();
});
